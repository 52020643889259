import logo from './logo.svg';
import './App.css';
import {BottomCred} from './style.js'
import {Grommet} from 'grommet';


function Bottom() {
  return (
      <BottomCred> Created by Nium. Happy Three Months. Love you! </BottomCred>
  );
}

export default Bottom;
